<template>
  <div class="partnership">
    <div class="partnership__referral-link">
      <x-link
        primary
        @click="onLinkClick"
      >
        Реферальная ссылка
      </x-link>

      <transition name="fade">
        <div
          v-if="isLinkCopied"
          class="partnership__notification"
        >
          <x-icon
            name="check"
            size="s"
            class="partnership__notification-icon"
          />
          Ссылка успешно скопирована!
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { referralKey } from '@/constants/settings'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('profile')

export default {
  data () {
    return {
      isLinkCopied: false
    }
  },

  computed: {
    ...mapState(['user']),

    referralLink () {
      const query = `${referralKey}=${this.user.id}`
      return `${location.origin}?${query}`
    }
  },

  methods: {
    onLinkClick () {
      navigator.clipboard.writeText(this.referralLink)
      this.isLinkCopied = true
      setTimeout(() => (this.isLinkCopied = false), 3000)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .partnership
    &__referral-link
      display flex
      align-items center

    &__notification
      display flex
      align-items center
      margin-left 10px

    &__notification-icon
      color $colors.success

  .fade-enter-active,
  .fade-leave-active
    transition opacity .3s

  .fade-enter,
  .fade-leave-to
    opacity 0
</style>
